<script lang="ts">
  import Section from "$lib/components/Layout/Section.svelte";
  import { Route, route_for } from "$lib/route_list";
</script>

<div class="base">
  <Section single_pane={true} title="Welcome to Locational Algorithm Runner">
    <div class="p-5">
      <div class="block">
        The Locational tool lets users run their own data through Locational
        algorithms.
      </div>

      <div class="block">
        The tool is built around the idea of an algorithm ‘run’. A single run
        represents the end to end process of creating a package of input data
        and algorithm parameters, sending the package to the algorithm for
        processing and obtaining a result.
      </div>

      <img
        src="website workflow.png"
        alt="Locational tool workflow"
        class="mt-2 mb-5"
      />

      <div class="block">
        The tool lets you create new runs and view previous ones, whether these
        have completed or not. You can only run each algorithm run a single
        time. If you want to re-run using the same input data with slightly
        different parameters, you need to make a new algorithm run with a copy
        of the data. This means each run is a self-contained, unchangeable
        record, and avoids later confusion about exactly what data was used with
        which algorithm.
      </div>

      <div class="block">
        You need an account to access LARI. If you have one, click <a
          href={route_for(Route.login)}>login</a
        >
        to get started. Or
        <a href="mailto:hello@locational.io?subject=LARI access">message us</a> to
        request an account.
      </div>
    </div>
  </Section>
</div>

<style lang="scss">
  @import "../../lib/css/_variables";

  .base {
    margin: 0 auto;
    padding: 1vh;
    min-height: 80vh;
    max-width: 100%;
    background-color: $dark-background;
  }
</style>
